import {ref, remove, set} from "firebase/database";
import { toggleNotification, changeMessage, changeType } from './notification-actions';

export function addPlayer(player, dispatch, database) {
    dispatch({
        type: "PLAYERS/ADD/SUBMITTED",
    });
    
    let playerRef = ref(database, "players/" + player.uid)

    set(playerRef, player).then(() => {
        dispatch({
            type: "PLAYERS/ADD/SUCCESS"
        })
        changeMessage("Saved " + player.first_name + " successfully", dispatch)
        changeType("SUCCESS", dispatch)
        toggleNotification(dispatch)
    })
}

export function editPlayer(playerUID, dispatch) {
    dispatch({
        type: "PLAYERS/EDIT",
        payload: playerUID
    })

    dispatch({
        type: "PLAYERS/WIZARD/TOGGLE"
    })
}

export function removePlayer(player, dispatch, database) {
    if(player) {
        // eslint-disable-next-line no-restricted-globals
        let deletePlayer = confirm("Are you sure you want to delete " + player.first_name);
        if (deletePlayer) {
            dispatch({
                type: "PLAYERS/REMOVE/SUBMITTED",
            });

            let playerRef = ref(database, "players/" + player.uid)

            remove(playerRef).then(() => {
            dispatch({
                type: "PLAYERS/REMOVE/SUCCESS"
                })
            })
            changeMessage("Removed " + player.first_name + " successfully", dispatch)
            changeType("INFO", dispatch)
            toggleNotification(dispatch)
        }
    } else {
        changeMessage("Cannot find player in database", dispatch)
        changeType("ERROR", dispatch)
        toggleNotification(dispatch)
    }
}

export function togglePlayerWizard(dispatch) {
    dispatch({
        type: "PLAYERS/WIZARD/TOGGLE",
    });
}

export function clearWizard(dispatch) {
    dispatch({
        type: "PLAYERS/WIZARD/CLEAR",
    });
}