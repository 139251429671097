import playerReducer from './player-reducer'
import teamsReducer from './team-reducer'
import notificationReducer from './notification-reducer'
import gamesReducer from './games-reducer'
import { combineReducers } from 'redux'
import settingsReducer from './settings-reducer'
import archiveReducer from './archive-reducer'
import cvcReducer from './cvc-reducer'

const allReducers = combineReducers({
    players: playerReducer,
    teams: teamsReducer,
    notifications: notificationReducer,
    games: gamesReducer,
    settings: settingsReducer,
    archive: archiveReducer,
    cvc: cvcReducer
})

export default allReducers