let actionTimeout = null;

export function changeType(type, dispatch) {
    dispatch({
        type: "NOTIFICATION/SETTYPE",
        payload: type
    });
}

export function changeMessage(message, dispatch) {
    dispatch({
        type: "NOTIFICATION/SETMESSAGE",
        payload: message
    });
}


export function toggleNotification(dispatch) {
    if (actionTimeout != null) {
        clearTimeout(actionTimeout);
    }
    dispatch({
        type: "NOTIFICATION/TOGGLEON"
    });
    actionTimeout = setTimeout(() => {
        dispatch({
            type: "NOTIFICATION/TOGGLEOFF"
        });
        actionTimeout = null
    }, 5000)
}