const playerReducer = (
    state = {
        type: "SUCCESS",
        showNotification: false,
        message: ""
    }, action
    ) => {
    switch (action.type) {
        case 'NOTIFICATION/TOGGLEON':
            return {
                ...state,
                showNotification: true
            }
        case 'NOTIFICATION/TOGGLEOFF':
            return {
                ...state,
                showNotification: false
            }
        case 'NOTIFICATION/SETMESSAGE':
            return {
                ...state,
                message: action.payload
            }
        case 'NOTIFICATION/SETTYPE':
            return {
                ...state,
                type: action.payload
            }
        default:
            return state
    }
}

export default playerReducer