import React, {useEffect, useState} from 'react';
import Loader from "./Loader";
import {useFirebase} from "../firebase-provider";
import {off, onValue, ref} from "firebase/database";

function formatDateAsYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}${month}${day}`;
}

function formatDate(date) {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const dayOfWeek = days[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = months[date.getMonth()];

    const ordinalSuffix = getOrdinalSuffix(dayOfMonth);

    return `${dayOfWeek} ${dayOfMonth}${ordinalSuffix} ${month}`;
}

function getOrdinalSuffix(day) {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
    }
}

function ArchiveSingle(props) {
    const { date } = props;
    const [isLoading, setIsLoading] = useState(true);
    const formattedDate = formatDateAsYYYYMMDD(date);
    const [archives, setArchives] = useState([]);
    const { database } = useFirebase();

    useEffect(() => {
        setIsLoading(true)
        const archiveRef = ref(database, "completedDaily/" + formattedDate);
        onValue(archiveRef, (rawArchives) => {
            setIsLoading(false);
            const archives = [];
            if(rawArchives.exists()) {
                rawArchives.forEach((archive) => {
                    archives.push(archive.val());
                });
                setArchives(archives);
            } else {
                setArchives([]);
            }
        });

        return () => {
            off(archiveRef);
        }
    }, [formattedDate, database]);

    if(isLoading) {
        return <Loader />
    }

    if(archives.length === 0) {
        return (
            <div className="flex w-full justify-center my-4">
                <div>No archives found for this date</div>
            </div>
        )
    }

    return (
        <div className="flex w-full max-w-3xl flex-col justify-center items-center m-auto mb-8">
            <h3 className="text-xl font-bold mt-4">{formatDate(date)}</h3>
            <div className="flex flex-col mt-4 gap-2 w-full justify-center items-center">
                {archives.map((archive, index) => {
                    return (
                        <div className="flex flex-row max-w-2xl bg-white shadow-md rounded-lg p-4 w-full">
                            <div key={index} className="flex flex-1 flex-col justify-center items-center">
                                <h4 className="text-lg font-bold">{archive.team_one.team_name}</h4>
                                <p className="text-blue-400 text-2xl font-medium">{archive.team_one.team_score}</p>
                            </div>
                            <div className="w-1 h-20 bg-blue-100"></div>
                            <div key={index} className="flex flex-1 flex-col justify-center items-center">
                                <h4 className="text-lg font-bold">{archive.team_two.team_name}</h4>
                                <h2 className="text-blue-400 text-2xl font-medium">{archive.team_two.team_score}</h2>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ArchiveSingle