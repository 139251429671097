import {ref, remove, set} from "firebase/database";
import { addTeamToRounds, removeTeamFromGamesWithUid } from './game-actions';
import { toggleNotification, changeMessage, changeType } from './notification-actions';

export function addTeam(team, season, cloudSettings, editing, dispatch, database) {
    dispatch({
        type: "TEAMS/ADD/SUBMITTED",
    });
    if (editing && (team.display_only === undefined || team.display_only === false)) {
        removeTeam(team, season, cloudSettings, editing, dispatch, database)
    } else if((team.display_only !== undefined && team.display_only === true)) {
        const teamRef = ref(database, "teams/" + team.uid);
        set(teamRef, team).then(() => {
            dispatch({
                type: "TEAMS/ADD/SUCCESS"
            })
            clearWizard(dispatch);
            changeMessage("Saved " + team.team_name + " successfully", dispatch)
            changeType("SUCCESS", dispatch)
            toggleNotification(dispatch)
        })
    } else {
        addTeamToRounds(team, season, cloudSettings, dispatch, database, (didComplete) => {
            team = {...team, grade: parseInt(team.grade)}
            if(didComplete) {
                let teamRef = ref(database, "teams/" + team.uid);
                set(teamRef, team).then(() => {
                    dispatch({
                        type: "TEAMS/ADD/SUCCESS"
                    })
                    clearWizard(dispatch);
                    changeMessage("Saved " + team.team_name + " successfully", dispatch)
                    changeType("SUCCESS", dispatch)
                    toggleNotification(dispatch)
                })
            }
        })
    }
}

export function editTeam(teamUID, dispatch) {
    dispatch({
        type: "TEAMS/EDIT",
        payload: teamUID
    })

    dispatch({
        type: "TEAMS/WIZARD/TOGGLE"
    })
}

export function removeTeam(team, season, cloudSettings, editing, dispatch, database) {
    if(!editing) {
        // eslint-disable-next-line no-restricted-globals
        let deleteTeam = confirm("Are you sure you want to delete " + team.team_name);
        if (deleteTeam) {
            if(team.display_only !== undefined && team.display_only === true) {
                dispatch({
                    type: "TEAMS/REMOVE/SUBMITTED",
                });
                let playerRef = ref(database, "teams/" + team.uid)

                remove(playerRef).then(() => {
                    dispatch({
                        type: "TEAMS/REMOVE/SUCCESS"
                    })
                })
                
                changeMessage("Deleted " + team.team_name + " successfully", dispatch)
                changeType("SUCCESS", dispatch)
                toggleNotification(dispatch)
            } else {
                removeTeamFromGamesWithUid(team.uid, season.season, cloudSettings, dispatch, database, (didComplete) => {
                    if(didComplete) {
                        dispatch({
                            type: "TEAMS/REMOVE/SUBMITTED",
                        });
                
                        let playerRef = ref(database, "teams/" + team.uid)
                
                        remove(playerRef).then(() => {
                            dispatch({
                                type: "TEAMS/REMOVE/SUCCESS"
                            })
                        })
                
                        changeMessage("Deleted " + team.team_name + " successfully", dispatch)
                        changeType("SUCCESS", dispatch)
                        toggleNotification(dispatch)
                    }
                })
            }
        }
    } else {
        removeTeamFromGamesWithUid(team.uid, season.season, cloudSettings, dispatch, database, (didComplete) => {
            if(didComplete) {
                addTeam(team, season, cloudSettings, false, dispatch, database);
            }
        })
    }
}

export function toggleTeamWizard(dispatch, division) {
    dispatch({
        type: "TEAMS/WIZARD/TOGGLE",
        payload: division
    });
}

export function clearWizard(dispatch) {
    dispatch({
        type: "TEAMS/WIZARD/CLEAR",
    });
}