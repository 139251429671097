import React from 'react';
import volleyballBG from '../../assets/volleyball-login-bg.jpg';
import logo from '../../assets/townbeach-logo.png';
import {useFirebase} from "../../firebase-provider";
import { signInWithEmailAndPassword } from "firebase/auth";

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            isLoading: false,
            showError: false,
            error: ""
        }
    }

    render() {

        const ButtonLoader = () => {
            if(this.state.isLoading) {
                return (
                    <svg className="inline animate-spin -ml-1 mr-3 h-5 w-5 -ml-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                )
            } else {
                return null
            }
        }

        const ErrorMessage = () => {
            if (this.state.showError) {
                return(
                    <div className="px-2 absolute -bottom-40">
                        <div className="inline-flex items-center bg-white leading-none text-red-400 rounded-full p-4 shadow text-red text-md">
                        <span className="inline-flex bg-red-600 text-white rounded-full h-6 px-3 justify-center items-center">Error</span>
                        <span className="inline-flex px-2">{this.state.error}</span>
                        </div>
                    </div>
                )
            } else {
                return null;
            }
        }

        const loginWithEmailAndPassword = e => {
            e.preventDefault();
            this.setState({isLoading: true});
            let email = this.state.email;
            let password = this.state.password;
            signInWithEmailAndPassword(this.props.firebaseAuth, email, password)
            .then((user) => {
                this.setState({isLoading: false});
            })
            .catch((error) => {
                this.setState({isLoading: false});
                this.setState({showError: true});
                this.setState({error: error.message});
            });
        }

        const inputChangeHandler = (event) => {
            let inputName = event.target.name;
            this.setState({[inputName] : event.target.value});
        }

        return (
            <div className="w-full h-full flex flex-wrap">

                <div className="w-full lg:w-1/2 flex flex-col justify-center items-center">

                    <div className="flex justify-center items-center pt-12 md:mb-2">
                        <img alt="Townbeach logo" src={logo} className="w-64 p-4" />
                    </div>

                    <div className="w-4/5 xl:w-1/2 m-10 py-10 pt-0 mt-0 flex flex-col justify-center md:justify-start px-8 md:px-18 lg:px-24 xl:px-8 rounded-lg">
                        <p className="text-center text-black text-3xl">Welcome.</p>
                        <form className="flex flex-col pt-3 md:pt-8 relative" onSubmit={loginWithEmailAndPassword}>
                            <div className="flex flex-col pt-4">
                                <label htmlFor="email" className="text-black text-lg">Email</label>
                                <input type="email" onChange={inputChangeHandler} name="email" placeholder="example@hotmail.com" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline"/>
                            </div>
            
                            <div className="flex flex-col pt-4">
                                <label htmlFor="password" className="text-lack text-lg">Password</label>
                                <input type="password" onChange={inputChangeHandler} name="password" placeholder="*************" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline"/>
                            </div>
            
                            <button type="submit" className="bg-blue-400 rounded-md text-white font-bold text-lg hover:bg-blue-500 p-2 mt-8 cursor-pointer">
                            <ButtonLoader/>
                            Log In
                            </button>
                            <ErrorMessage/>
                        </form>
                    </div>

                </div>
                
                <div className="w-1/2 hidden lg:block">
                    <img className="object-cover w-full h-screen" alt="Volleyball background half screen" src={volleyballBG}/>
                </div>
            </div>
        )
    }
}

export default function LoginWithFirebase(props) {
    const { auth: firebaseAuth } = useFirebase();
    return <Login {...props} firebaseAuth={firebaseAuth} />;
}