const settingsReducer = (
    state = {
        cloudSettings: null
    }, action
    ) => {
    switch (action.type) {
        case 'SETTINGS/UPDATE/SUBMITTED':
            return {
                ...state,
            }
        case 'SETTINGS/UPDATE/SUCCESS':
            return {
                ...state,
                cloudSettings: action.payload
            }
        default:
            return state
    }
}

export default settingsReducer