import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";

import Toast from '../../utilities/toast';

import Players from '../Players';
import Teams from '../Teams';
import Games from '../Games';
import Archive from '../Archive';
import Settings from '../Settings';
import CVC from '../CVC';

function Frame() {

    return (
        <div>
            <Toast/>
            <Switch>
                <Route path="/games">
                    <Games />
                </Route>
                <Route path="/players">
                    <Players />
                </Route>
                <Route path="/teams">
                    <Teams />
                </Route>
                <Route path="/archive">
                    <Archive />
                </Route>
                <Route path="/settings">
                    <Settings />
                </Route>
                <Route path="/cvc">
                    <CVC />
                </Route>
                <Route exact path="/">
                    <Redirect to="/teams" />
                </Route>
            </Switch>
        </div>
    )
}

export default Frame