import React, {useEffect, useState} from 'react';
import LiveStatus from './LiveStatus';
import {useDispatch, useSelector} from 'react-redux';
import Loader from './Loader';
import DatePicker from "react-datepicker";
import {
    changeSide,
    changeTeamsInDraw,
    generateSeason,
    moveDownQueue,
    moveRounds,
    moveUpQueue,
    saveSeasonAndMove
} from '../actions/game-actions';
import "react-datepicker/dist/react-datepicker.css";
import {changeMessage, changeType, toggleNotification} from '../actions/notification-actions';
import {useFirebase} from "../firebase-provider";

function Games() {

    const games = useSelector(state => state.games);

    const { database } = useFirebase();

    const teams = useSelector(state => state.teams);

    const cloudSettings = useSelector(state => state.settings.cloudSettings);

    let tempState = {
        selectedRound: 0,
        selectedStartDate: Date(),
        currentlySelectedGame: null,
        roundIsSet: false,
        seasonName: "",
        showDialog: false,
        editDialogData: {team1: "", team2: "", team1Change: "", team2Change: "", week: 0, seasonIndex: 0}
    }

    let [state, setState] = useState(tempState)
    let [startDate, setStartDate] = useState(null);

    useEffect(() => {
        if(cloudSettings !== null && !state.roundIsSet) {
            let startDates = cloudSettings.startDates;
            let current = new Date();
            for(let i = 0; i < startDates.length; i++) {
                let checkedDate = new Date(startDates[i]);
                if(current <= checkedDate) {
                    if(i === 0) {
                        setState({...state, selectedRound: i, roundIsSet: true});
                        break;
                    } else {
                        setState({...state, selectedRound: i - 1, roundIsSet: true});
                        break;
                    }
                }
            }
        }
    }, [cloudSettings]);

    const TIMES = ["6:00pm", "6:40pm", "7:20pm", "8:00pm", "8:40pm", "9:20pm", "10:00pm", "10:40pm", "11:20pm", "12:00am", "12:40am", "1:20am", "2:00am", "2:40am", "3:20am", "4:00am", "4:40am", "5:20am", "6:00am", "6:40am"];

    const roundButtonsGroup = [];

    const dispatch = useDispatch();

    const Dialog = () => {
        return (
            <div className="flex z-50 justify-center fixed left-0 right-0 top-0 bottom-0 h-screen items-center bg-opacity-30 bg-black antialiased">
                <div className="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 max-w-2xl mx-auto rounded-lg border border-gray-300 shadow-xl">
                    <div className="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
                        <p className="font-semibold text-gray-800">Edit Game</p>
                        <svg onClick={() => {
                            setState({...state, showDialog: false})
                        }} className="w-6 h-6 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                    </div>
                    <div className="flex flex-col items-center px-6 py-5 bg-gray-50">
                        <span className="mb-5">{getTeamNameFromUid(state.editDialogData.team1)} <i>vs</i> {getTeamNameFromUid(state.editDialogData.team2)}</span>
                        {state.editDialogData.team1 !== "fill" ? (
                            <div className="flex flex-row justify-center"><div className="inline-flex relative w-full justify-center items-center">
                                    <svg className="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232"><path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#648299" fillRule="nonzero" /></svg>
                                    <select value={state.editDialogData.team1Change} onChange={e => {setState({
                                        ...state,
                                        editDialogData: {
                                            team1: state.editDialogData.team1,
                                            team2: state.editDialogData.team2,
                                            team1Change: e.target.value,
                                            team2Change: state.editDialogData.team2Change
                                        }
                                    })}} className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker">
                                        {Object.keys(teams.teams).map((teamuid, index) => {return(<option key={index} value={teamuid}>{getTeamNameFromUid(teamuid)}</option>)})}
                                    </select>
                                </div>
                            </div>
                        ) : (
                            <div className="flex flex-row justify-center">
                                <div className="inline-flex relative w-full justify-center items-center">
                                    <svg className="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232"><path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#648299" fillRule="nonzero" /></svg>
                                    <select value={"fill"} disabled className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker">
                                        <option value={"fill"}>Fill</option>
                                    </select>
                                </div>
                            </div>
                        )}
                        vs
                        {state.editDialogData.team2 !== "fill" ? (
                        <div className="flex flex-row justify-center"><div className="inline-flex relative w-full justify-center items-center">
                                <svg className="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232"><path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#648299" fillRule="nonzero" /></svg>
                                <select value={state.editDialogData.team2Change} onChange={e => {setState({
                                    ...state,
                                    editDialogData: {
                                        team1: state.editDialogData.team1,
                                        team2: state.editDialogData.team2,
                                        team1Change: state.editDialogData.team1Change,
                                        team2Change: e.target.value
                                    }
                                })}} className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker">
                                    {Object.keys(teams.teams).map((teamuid, index) => {return(<option key={index} value={teamuid}>{getTeamNameFromUid(teamuid)}</option>)})}
                                </select>
                            </div>
                        </div>
                        ) : (
                            <div className="flex flex-row justify-center">
                                <div className="inline-flex relative w-full justify-center items-center">
                                    <svg className="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232"><path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#648299" fillRule="nonzero" /></svg>
                                    <select value={"fill"} disabled className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker">
                                        <option value={"fill"}>Fill</option>
                                    </select>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex flex-row items-center justify-between p-5 bg-white border-t border-gray-200 rounded-bl-lg rounded-br-lg">
                        <p className="cursor-pointer font-semibold text-gray-600 hover:text-gray-700" onClick={() => {
                            setState({...state, showDialog: false})
                        }}>Cancel</p>
                        <button onClick={() => {
                            changeTeamsInDraw(state.editDialogData.team1, state.editDialogData.team2, state.editDialogData.team1Change, state.editDialogData.team2Change, state.selectedRound, games.season, dispatch, database, function(result){
                            if(result) {
                                changeMessage("Moved games successfully", dispatch)
                                changeType("SUCCESS", dispatch)
                                toggleNotification(dispatch)
                                setState({...state, showDialog: false})
                            } else {
                                changeMessage("An error occured", dispatch)
                                changeType("ERROR", dispatch)
                                toggleNotification(dispatch)
                                setState({...state, showDialog: false})
                            }
                        })}} className="px-4 py-2 text-white font-semibold bg-blue-400 rounded hover:bg-blue-500">Save</button>
                    </div>
                </div>
            </div>
        )
    }

    function dateToString(date) {
        let newDate = new Date(date);
        var dd = newDate.getDate();
        var mm = newDate.getMonth()+1; 
        var yyyy = newDate.getFullYear();
        if(dd<10) 
        {
            dd='0'+dd;
        } 

        if(mm<10) 
        {
            mm='0'+mm;
        } 
        return dd+'-'+mm+'-'+yyyy;
    }

    const OrderBlock = () => {
        let currentCourt = state.currentlySelectedGame[4];
        let courts = cloudSettings.courts;
        //If two courts
        if(courts.length === 2) {
            let opposingCourt;
            if(currentCourt === 0) {
                opposingCourt = 1;
            } else {
                opposingCourt = 0;
            }
            return (
                <div>
                    <div className="fixed w-full h-16 bg-blue-400 text-white flex flex-row bottom-0 left-0">
                        <button onClick={() => {
                            moveUpQueue(state.currentlySelectedGame[2], state.selectedRound, games.season, dispatch, database)
                        }} className="w-1/3 h-full text-xl border-r-2"><i className="fas fa-arrow-up"></i></button>
                        <button onClick={() => {
                            moveDownQueue(state.currentlySelectedGame[2], state.selectedRound, games.season, dispatch, database)
                        }} className="w-1/3 h-full text-xl"><i className="fas fa-arrow-down"></i></button>
                        <button onClick={() => {
                            changeSide(state.currentlySelectedGame[2], state.selectedRound, games.season, opposingCourt, dispatch, database)
                            setState({...state, currentlySelectedGame: null})
                        }} className="w-1/3 h-full border-l-2 font-bold">To {(courts[opposingCourt].name)}</button>
                    </div>
                </div>
            )
        } else if (courts.length === 1) {
            return (
                <div>
                    <div className="fixed w-full h-16 bg-blue-400 text-white flex flex-row bottom-0 left-0">
                        <button onClick={() => {
                            moveUpQueue(state.currentlySelectedGame[2], state.selectedRound, games.season, dispatch, database)
                        }} className="w-1/2 h-full text-xl border-r-2"><i className="fas fa-arrow-up"></i></button>
                        <button onClick={() => {
                            moveDownQueue(state.currentlySelectedGame[2], state.selectedRound, games.season, dispatch, database)
                        }} className="w-1/2 h-full text-xl"><i className="fas fa-arrow-down"></i></button>
                    </div>
                </div>
            )
        } else if (courts.length > 2) {
            return (
                <div>
                    <div className="fixed w-full h-16 bg-blue-400 text-white flex flex-row bottom-0 left-0">
                        <button onClick={() => {
                            moveUpQueue(state.currentlySelectedGame[2], state.selectedRound, games.season, dispatch, database)
                        }} className="w-1/3 h-full text-xl border-r-2"><i className="fas fa-arrow-up"></i></button>
                        <button onClick={() => {
                            moveDownQueue(state.currentlySelectedGame[2], state.selectedRound, games.season, dispatch, database)
                        }} className="w-1/3 h-full text-xl"><i className="fas fa-arrow-down"></i></button>
                        <button onClick={() => {}} className="w-1/3 h-full border-l-2 font-bold">Court Change Not Active</button>
                    </div>
                </div>
            )
        }
    }

    const TitleBlock = () => {
        return (
            <div>
                <h2 className="text-2xl mt-2 font-bold items-center leading-7 text-gray-900 sm:text-3xl sm:truncate inline-flex sm:align-top">
                   {cloudSettings.seasonName ? "Games | " + cloudSettings.seasonName : "Games"} 
                </h2>
                <h2 className="mt-3 font-bold">Rounds</h2>
                <div className="flex flex-col justify-center items-center">
                    <div>
                        {roundButtonsGroup}
                    </div>
                    
                    <div className="flex flex-row items-center p-5 pb-0">
                        <h2 className="text-lg font-medium">Round {state.selectedRound + 1} |</h2>
                        <EditCalenderStartDate />
                    </div>

                    <button onClick={() => {
                        CopyGames();
                        let containerid = "copyField";
                        if (window.getSelection) {
                            if (window.getSelection().empty) { // Chrome
                                window.getSelection().empty();
                            } else if (window.getSelection().removeAllRanges) { // Firefox
                                window.getSelection().removeAllRanges();
                            }
                        } else if (document.selection) { // IE?
                            document.selection.empty();
                        }

                        let range;
                    
                        if (document.selection) {
                            range = document.body.createTextRange();
                            range.moveToElementText(document.getElementById(containerid));
                            range.select().createTextRange();
                            document.execCommand("copy");
                        } else if (window.getSelection) {
                            range = document.createRange();
                            range.selectNode(document.getElementById(containerid));
                            window.getSelection().addRange(range);
                            document.execCommand("copy");
                        }
                        
                        changeMessage("Copied Successfully", dispatch)
                        changeType("INFO", dispatch)
                        toggleNotification(dispatch)
                    }} className="bg-blue-400 rounded-md text-white font-medium text-lg hover:bg-blue-500 p-1 px-4 mt-2 cursor-pointer flex flex-row justify-center items-center"><i className="fab fa-facebook mr-2 text-3xl"></i> Copy this weeks rounds</button>
                </div>
            </div>
        )
    }

    const EditCalenderStartDate = () => {
        const CustomInput = React.forwardRef(
            ({ value, onClick }, ref) => (
                <div className="flex flex-row justify-center items-center">
                    <svg className="shadow-md rounded-full cursor-pointer fill-info" width="35px" height="35px" viewBox="0 0 814 814" onClick={onClick} ref={ref} >
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="edit">
                                <circle id="Oval" fill="currentColor" cx="407" cy="407" r="407"></circle>
                                <polygon id="Combined-Shape" fill="#FFFFFF" fillRule="nonzero" points="154 553.95466 154 659 259.045673 659 569 349.04534 463.954327 244"></polygon>
                                <path d="M650.781831,227.824593 L585.17477,162.218391 C574.216992,151.260536 556.375043,151.260536 545.417265,162.218391 L494,213.636015 L599.363249,319 L650.780514,267.582375 C661.739609,256.624521 661.739609,238.782447 650.781831,227.824593 Z" id="Shape" fill="#FFFFFF" fillRule="nonzero"></path>
                            </g>
                        </g>
                    </svg>
                </div>
            )
        );
        const [startDate, setStartDate] = useState(new Date(cloudSettings.startDates[state.selectedRound]));
        return (
            <div className="flex flex-row justify-center items-center">
                <h3 className="mx-2 flex flex-row justify-center items-center">{dateToString(startDate)}</h3>
                <DatePicker
                selected={startDate}
                onChange={date => {
                    // eslint-disable-next-line no-restricted-globals
                    let confirmedChange = confirm("Are you sure you want to change the date of this round? This will effect all rounds following.")
                    if (confirmedChange) {
                        setStartDate(date);
                        moveRounds(state.selectedRound, date, dispatch, cloudSettings, database);
                    }
                }}
                withPortal
                filterDate={isMonday}
                customInput={<CustomInput />}
                />
            </div>
        );
    }

    const RoundButton = (props) => {

        if(state.selectedRound === props.round) {
            return (
                <button className="p-2 w-14 border border-blue-400 bg-blue-400 text-white">{props.round + 1}</button>
            )
        } else {
            return (
                <button onClick={() => {setState({...state, currentlySelectedGame: null, selectedRound: props.round})}} className="p-2 w-14 border border-gray-400">{props.round + 1}</button>
            )
        }
    }

    const isMonday = date => {
        const day = date.getDay();
        return day !== 0 && day !== 2 && day !== 3 && day !== 4 && day !== 5 && day !== 6;
    };
    const TeamName = () => {
        return (
            <div className="mb-4 w-200">
                <label className="block text-grey-darker text-sm font-regular mb-2 mt-5" htmlFor="nameOfSeason">
                    Name of season
                </label>
                <input key="nameOfSeason" className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="nameOfSeason" type="text" onChange={(e) => setState({...state, seasonName: e.target.value})} value={state.seasonName} />
            </div>
        )
    }

    const SeasonNeeded = () => {

        const CustomInput = React.forwardRef(
            ({ value, onClick }, ref) => (
                <button className="border border-gray-400 rounded-md text-black font-light text-md hover:border-gray-500 p-2 mt-8 cursor-pointer" onClick={onClick} ref={ref}>
                    <i className="far fa-calendar-alt"></i> {value ? value : "Choose Start Date"}
                </button>
            )
        );
        return (
            <div>
                <div className="px-6">
                    {LiveStatus()}
                    <h2 className="text-2xl mt-2 font-bold items-center leading-7 text-gray-900 sm:text-3xl sm:truncate inline-flex sm:align-top">
                        Games
                    </h2>
                    <div className="mx-6 mt-2 p-8 lg:mx-8 text-center flex flex-col items-center"> 
                        <h2 className="text-xl font-bold">No season found</h2>
                        {TeamName()}
                        <p>Please select a date for the new season to start</p>
                        <DatePicker dateFormat="dd/MM/yyyy" customInput={<CustomInput />} filterDate={isMonday} selected={startDate} onChange={date => setStartDate(date)} />
                        {
                            startDate ?
                            <button disabled={teams === null} onClick={() => {generateSeason(teams.teams, startDate, cloudSettings, state.seasonName, dispatch, database)}} className="bg-blue-400 rounded-md text-white font-bold text-lg hover:bg-blue-500 p-2 mt-4 cursor-pointer">
                                Create New Season
                            </button>
                            :
                            <button disabled="disabled" className="bg-gray-400 rounded-md text-white font-bold text-lg p-2 mt-4">
                                Create New Season
                            </button>
                        }
                    </div>
                </div>
            </div>
        )
    }

    const GameCard = (props) => {
        if(state.currentlySelectedGame && props.game[2] === state.currentlySelectedGame[2]) {
            return (
                <div className="w-full flex flex-col sm:flex-row">
                    <div onClick={() => {
                            if(state.currentlySelectedGame[2] === props.game[2]) {
                                setState({...state, currentlySelectedGame: null})
                            } else {
                                setState({...state, currentlySelectedGame: props.game})
                            }
                        }} className="w-full border-t sm:border-b border-l border-r border-gray-400 py-3 px-5 flex flex-col justify-center items-center bg-blue-200">
                        <div className="text-sm font-light text-gray-700">Division: {getTeamDivFromUid(props.game[0], props.game[1])}</div>
                        <div className="flex flex-row justify-center">
                            <div className="font-bold text-gray-700">{getTeamNameFromUid(props.game[0])}</div>
                            <span className="font-light mx-2 text-gray-700">vs</span>
                            <div className="font-bold text-gray-700">{getTeamNameFromUid(props.game[1])}</div>
                        </div>
                    </div>
                    {<div onClick={() => {
                            setState({...state, showDialog: true, editDialogData: {team1: props.game[0], team2: props.game[1], team1Change: props.game[0], team2Change: props.game[1]}})
                        }} className="cursor-pointer sm:w-20 w-full bg-blue-400 border border-gray-400 flex justify-center items-center">
                        <svg className="cursor-pointer fill-info" width="35px" height="35px" viewBox="0 0 814 814" >
                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="edit">
                                    <polygon id="Combined-Shape" fill="#FFFFFF" fillRule="nonzero" points="154 553.95466 154 659 259.045673 659 569 349.04534 463.954327 244"></polygon>
                                    <path d="M650.781831,227.824593 L585.17477,162.218391 C574.216992,151.260536 556.375043,151.260536 545.417265,162.218391 L494,213.636015 L599.363249,319 L650.780514,267.582375 C661.739609,256.624521 661.739609,238.782447 650.781831,227.824593 Z" id="Shape" fill="#FFFFFF" fillRule="nonzero"></path>
                                </g>
                            </g>
                        </svg>
                    </div>}
                </div>
            )
        } else {
            return (
                <div className="w-full flex flex-col sm:flex-row">
                    <div onClick={() => {setState({...state, currentlySelectedGame: props.game})}} className="w-full border-t sm:border-b border-l border-r border-gray-400 py-3 px-5 flex flex-col justify-center items-center bg-white">
                    <div className="text-sm font-light text-gray-700">Division: {getTeamDivFromUid(props.game[0], props.game[1])}</div>
                        <div className="flex flex-row justify-center">
                            <div className="font-bold text-gray-700">{getTeamNameFromUid(props.game[0])}</div>
                            <span className="font-light text-gray-700 mx-2">vs</span>
                            <div className="font-bold text-gray-700">{getTeamNameFromUid(props.game[1])}</div>
                        </div>
                    </div>
                    {<div onClick={() => {
                            setState({...state, showDialog: true, editDialogData: {team1: props.game[0], team2: props.game[1], team1Change: props.game[0], team2Change: props.game[1], week: state.selectedRound}})
                        }} className="cursor-pointer sm:w-20 w-full bg-blue-300 border border-gray-400 flex justify-center items-center">
                        <svg className="fill-info" width="35px" height="35px" viewBox="0 0 814 814">
                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="edit">
                                    <polygon id="Combined-Shape" fill="#FFFFFF" fillRule="nonzero" points="154 553.95466 154 659 259.045673 659 569 349.04534 463.954327 244"></polygon>
                                    <path d="M650.781831,227.824593 L585.17477,162.218391 C574.216992,151.260536 556.375043,151.260536 545.417265,162.218391 L494,213.636015 L599.363249,319 L650.780514,267.582375 C661.739609,256.624521 661.739609,238.782447 650.781831,227.824593 Z" id="Shape" fill="#FFFFFF" fillRule="nonzero"></path>
                                </g>
                            </g>
                        </svg>
                    </div>}
                </div>
            )
        }
    }

    const TimeSlot = (props) => {
        let index = props.index
        return (
            <h2 className="font-bold sm:pr-5 pr-0">{TIMES[index]}</h2>
        )
    }

    function getTeamDivFromUid(team1Uid, team2Uid) {
        let idToUse = team1Uid;
        if(team1Uid === "fill") {
            idToUse = team2Uid;
        }
        for(let i = 0; i < Object.keys(teams.teams).length; i++) {
            if(teams.teams[Object.keys(teams.teams)[i]].uid === idToUse) {
                let div = teams.teams[Object.keys(teams.teams)[i]].grade;
                return cloudSettings.divisions[div].name;
            }
        }
    }

    function getTeamNameFromUid(teamUid) {
        if(teamUid === "fill") {
            return "Fill"
        }
        for(let i = 0; i < Object.keys(teams.teams).length; i++) {
            if(teams.teams[Object.keys(teams.teams)[i]].uid === teamUid) {
                return teams.teams[Object.keys(teams.teams)[i]].team_name;
            }
        }
    }

    function sortByTime(games) {
        games.sort(function(a, b) { 
            return a[3] - b[3]; 
        });
        return games;
    }

    const CopyGames = () => {

        let dayGames = {};
        let season = games.season;
        let roundKeys = Object.keys(season);
        for(let i = 0; i < roundKeys.length; i++){
            if(!dayGames[season[roundKeys[i]].day]) {
                dayGames[season[roundKeys[i]].day] = [];
            }
            let day = season[roundKeys[i]].games?.[state.selectedRound];
            if (!day) {
                continue;
            }
            for(let j = 0; j < day.length; j++) {
                dayGames[season[roundKeys[i]].day].push(season[roundKeys[i]].games[state.selectedRound][j])
            }
        }
        
        let mainDisplayData = [];
        let roundFilteredKeys = Object.keys(dayGames);
        for(let i = 0; i < roundFilteredKeys.length; i++){
            let data = dayGames[roundFilteredKeys[i]];
            sortByTime(data);
            mainDisplayData.push(
                <div key={roundFilteredKeys[i]+"title"}>
                    <br />
                    <br />
                    <h3 className="font-bold">🏐🏐🏐{roundFilteredKeys[i][0].toUpperCase() + roundFilteredKeys[i].slice(1).toLowerCase()}🏐🏐🏐</h3>
                </div>
            );
            for(let j = 0; j < cloudSettings.courts.length; j++) {
                let courtGames = [];
                let courtName = cloudSettings.courts[j].name;
                for(let x = 0; x < data.length; x++){
                    if(data[x][4] === j) {
                        let game = data[x];
                        courtGames.push(
                            <div key={game[2]}>{TIMES[game[3]]} | {getTeamNameFromUid(game[0])} vs {getTeamNameFromUid(game[1])}</div>
                        )
                    }
                }
                mainDisplayData.push(
                    <div key={courtName + roundFilteredKeys[i]}>
                        <br />
                        <h4 className="font-bold">{courtName}</h4>
                        <div>
                            {courtGames}
                        </div>
                    </div>
                );
            }            
        }
        mainDisplayData.push(
            <div key="otherData">
                <br />
                <h3 className="font-bold">🌭Thursday - Free BBQ & $10 games🌭</h3>
                <div>6:00pm | Round 1</div>
                <div>6:40pm | Dinner</div>
                <div>7:00pm | Round 2</div>
                <div>7:40pm | Round 3</div>
            </div>
        )
        
        return(
            <div style={{"left": "-1px", "marginTop": "-1px", "height": "1px", "width": "1px", "overflow": "hidden"}} id="copyField">
                {mainDisplayData}
            </div>
        )
    }

    const EndSeasonButton = () => {
        return (
            <div className="flex justify-center items-center">
                <button onClick={() => {
                    // eslint-disable-next-line no-restricted-globals
                    let confirmedChange = confirm("Are you sure you want to end this season. This will put the season into a saved archive state and you will no longer be able to make changes to it.")
                    if (confirmedChange) {
                        saveSeasonAndMove(games, dispatch, database);
                    }
                }} className="bg-red-400 rounded-md text-white font-medium text-lg hover:bg-red-500 p-2 px-4 mt-2 cursor-pointer flex flex-row justify-center items-center">Save, Archive and End Season</button>
            </div>
        )
    }

    const GamesBlock = () => {
        let dayGames = {};
        let season = games.season;
        let roundKeys = Object.keys(season);
        for(let i = 0; i < roundKeys.length; i++){
            if(!dayGames[season[roundKeys[i]].day]) {
                dayGames[season[roundKeys[i]].day] = [];
            }
            let day = season[roundKeys[i]].games?.[state.selectedRound];
            if (!day) {
                continue;
            }
            for(let j = 0; j < day.length; j++) {
                dayGames[season[roundKeys[i]].day].push(season[roundKeys[i]].games[state.selectedRound][j])
            }
        }
        let mainDisplayData = [];
        let roundFilteredKeys = Object.keys(dayGames);
        for(let i = 0; i < roundFilteredKeys.length; i++){
            let data = dayGames[roundFilteredKeys[i]];
            sortByTime(data);
            mainDisplayData.push(
                <h1 key={roundFilteredKeys[i] + "title"} className="font-medium sm:pl-2 sm:text-left w-full text-center mb-2 mt-5">{roundFilteredKeys[i][0].toUpperCase() + roundFilteredKeys[i].slice(1)}</h1>
            );
            for(let j = 0; j < cloudSettings.courts.length; j++) {
                let courtGames = [];
                let courtName = cloudSettings.courts[j].name;
                for(let x = 0; x < data.length; x++){
                    if(data[x][4] === j) {
                        let game = data[x];
                        courtGames.push(
                            <div key={game[2]} className="flex flex-col w-full items-center text-white space-between mt-3 sm:flex-row">
                                <TimeSlot index={game[3]}/>
                                <GameCard game={game}/>
                            </div>
                        )
                    }
                }
                if(j%2 === 0) {
                    mainDisplayData.push(
                        <div key={courtName + roundFilteredKeys[i]} className="bg-red-400 text-white mb-4 p-4 rounded-md">
                            <h2 className="font-bold text-center">{courtName}</h2>
                            <div className="flex text-black flex-col justify-center items-center">
                                {courtGames}
                            </div>
                        </div>
                    );
                } else {
                    mainDisplayData.push(
                        <div key={courtName + roundFilteredKeys[i]} className="bg-blue-400 text-white mb-4 p-4 rounded-md">
                            <h2 className="font-bold text-center">{courtName}</h2>
                            <div className="flex flex-col justify-center items-center">
                                {courtGames}
                            </div>
                        </div>
                    );
                }
            }            
        }
        
        return(
            <div className="sm:p-5 p-0 pt-0">
                <div className="mt-4 sm:p-4 p-1">
                    {mainDisplayData}
                </div>
            </div>
        )
    }

    if((!games.loading && games.season === null) || (!games.loading && games.season.length === 0 && cloudSettings !== null )) {
        return (
            <div>
                {SeasonNeeded()}
            </div>
        )
    } else if(!games.loading && games.season.length > 0 && cloudSettings !== null) {

        for (const [index] of cloudSettings.startDates.entries()) {
            roundButtonsGroup.push(<RoundButton key={index} round={index}/>)
        }

        return (
            <div>
                <div className="px-6">
                    {state.showDialog === true ? <Dialog /> : null }
                        <div>
                            <LiveStatus />
                            {TitleBlock()}
                            <GamesBlock />
                            {state.selectedRound === (roundButtonsGroup.length - 1) ? <EndSeasonButton /> : null }
                            <CopyGames />
                            <br />
                            {state.currentlySelectedGame ? <OrderBlock /> : null}
                        </div>
                </div>
            </div>
        )
    } else {
        return (
            <Loader />
        )
    }

    
}

export default Games