import React from 'react'
import Menu from './components/nav/Menu';
import Frame from './components/nav/Frame';
import Login from './components/auth/Login';
import Loader from './components/Loader';
import { useDispatch } from 'react-redux';
import { useFirebase, firebaseAuth } from "./firebase-provider";
import { ref, onValue, off } from "firebase/database";

function DataEntryPoint() {
  const dispatch = useDispatch();
  const { database } = useFirebase();

  React.useEffect(() => {

    dispatch({
      type: "CVC/UPDATE/SUBMITTED"
    })
    const cvcData = ref(database, 'resultBoard/');
    onValue(cvcData, (snapshot) => {
      const data = snapshot.val();
      dispatch({
        type: "CVC/UPDATE/SUCCESS",
        payload: data
      })
    });

    dispatch({
      type: "TEAMS/UPDATE/SUBMITTED"
    })

    const teamsRef = ref(database, 'teams/');
    onValue(teamsRef, (snapshot) => {
      const data = snapshot.val();
      dispatch({
        type: "TEAMS/UPDATE/SUCCESS",
        payload: data
      })
    });

    dispatch({
      type: "ARCHIVE/UPDATE/SUBMITTED"
    })
    const archiveRef = ref(database, 'completedKeys/');
    onValue(archiveRef, (snapshot) => {
      const data = snapshot.val();
      dispatch({
        type: "ARCHIVE/UPDATE/SUCCESS",
        payload: data
      })
    });

    dispatch({
      type: "PLAYERS/UPDATE/SUBMITTED"
    })
    const playersRef = ref(database, 'players/');
    onValue(playersRef, (snapshot) => {
      const data = snapshot.val();
      dispatch({
        type: "PLAYERS/UPDATE/SUCCESS",
        payload: data
      })
    });

    dispatch({
      type: "GAMES/UPDATE/SUBMITTED"
    })
    const gamesRef = ref(database, 'season/');
    onValue(gamesRef, (snapshot) => {
      const data = snapshot.val();
      dispatch({
        type: "GAMES/UPDATE/SUCCESS",
        payload: data
      })
    });

    dispatch({
      type: "SETTINGS/UPDATE/SUBMITTED"
    })
    const settingsRef = ref(database, 'settings/');
    onValue(settingsRef, (snapshot) => {
      const data = snapshot.val();
      dispatch({
        type: "SETTINGS/UPDATE/SUCCESS",
        payload: data
      })
    });

    return () => {
      // Cleanup listeners
      off(cvcData);
      off(teamsRef);
      off(archiveRef);
      off(playersRef);
      off(gamesRef);
      off(settingsRef);
    };
  }, [dispatch, database]);

  return null;
}

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isAuthenticated: false,
      loading: true
    }
  }

  componentDidMount() {
    firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({ isAuthenticated: true, loading: false })
      } else {
        this.setState({ isAuthenticated: false, loading: false })
      }
    });
  }

  render() {
    const MainContent = () => {
      if(this.state.isAuthenticated && !this.state.loading) {
        return (
            <div>
              <DataEntryPoint />
              <Menu/>
              <Frame/>
              <LoveKeegan />
            </div>
        )
      } else if (!this.state.isAuthenticated && !this.state.loading) {
        return (
            <Login/>
        )
      } else if (this.state.loading) {
        return (
            <Loader />
        )
      }
    }

    const LoveKeegan = () => {
      return (
          <div className="flex justify-center w-full text-gray-400 my-2">
            Made with <span className="text-red-400 ml-1 mr-1">❤</span> by <b className="ml-1">Keegan Cruickshank</b>
          </div>
      )
    }

    return (
        <div className="w-full h-full">
          <MainContent />
        </div>
    )
  }
}

export default App;
