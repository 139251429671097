const archiveReducer = (
    state = {
        archives: [],
        loading: true
    }, action
    ) => {
    switch (action.type) {
        case 'ARCHIVE/UPDATE/SUBMITTED':
            return {
                ...state
            }
        case 'ARCHIVE/UPDATE/SUCCESS':
            let keys = action.payload;
            return {
                ...state,
                archives: keys,
                loading: false
            }
        default:
            return state
    }
}

export default archiveReducer