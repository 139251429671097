
let defaultNewTeamState = {
    avaliability: {day: ""},
    captain: "",
    grade: "",
    players: [],
    team_name: "",
    uid: "",
    points: 0,
    display_only: false
}

const teamsReducer = (
    state = {
        teams: null,
        filter: "POINTS",
        wizard: false,
        editing_team: null,
        new_team: defaultNewTeamState,
        uploading_team: false
    },
    action) => {
    switch (action.type) {
        case 'TEAMS/EDIT':
            return {
                ...state,
                editing_team: action.payload
            }
        case 'TEAMS/FILTER':
            return {
                ...state,
                filter: action.payload
            }
        case 'TEAMS/UPDATE/SUBMITTED':
            return {
                ...state
            }
        case 'TEAMS/UPDATE/SUCCESS':
            return {
                ...state,
                teams: action.payload
            }
        case 'TEAMS/ADD/SUBMITTED':
            return {
                ...state,
                uploading_team: true
            }
        case 'TEAMS/ADD/SUCCESS':
            return { 
                ...state,
                new_team: defaultNewTeamState,
                wizard: false,
                uploading_team: false
            }
        case 'TEAMS/REMOVE/SUBMITTED':
            return {
                ...state
            }
        case 'TEAMS/REMOVE/SUCCESS':
            return {
                ...state
            }
        case 'TEAMS/WIZARD/TOGGLE':
            return {
                ...state,
                wizard: !state.wizard,
                new_team: {
                    ...state.new_team,
                    grade: action.payload
                }
            }

        case 'TEAMS/WIZARD/CLEAR':
            return {
                ...state,
                editing_team: null,
                new_team: defaultNewTeamState
            }
        default:
            return state
    }
}

export default teamsReducer