import React, { useState } from 'react';
import LiveStatus from './LiveStatus';
import DatePicker from "react-datepicker";
import './Archive.css';
import ArchiveSingle from "./ArchiveSingle";

function Archive() {
    const [selectedDate, setSelectedDate] = useState(new Date());

    const getNextDay = (date) =>{
        const nextDay = new Date(date);
        nextDay.setDate(date.getDate() + 1);
        return nextDay;
    }

    const getPreviousDay = (date) =>{
        const nextDay = new Date(date);
        nextDay.setDate(date.getDate() - 1);
        return nextDay;
    }

    const getDayFormatted = (date) => {
        // Get the next day
        const day = new Date(date);

        // Format the result
        const formatNumber = (num) => num.toString().padStart(2, '0');
        return `${formatNumber(day.getDate())}-${formatNumber(day.getMonth() + 1)}-${day.getFullYear()}`;
    }

    const TitleBlock = () => {
        return (
            <div>
                <h2 className="text-2xl mt-2 font-bold items-center leading-7 text-gray-900 sm:text-3xl sm:truncate inline-flex sm:align-top">
                   Archive | Past Games
                </h2>
            </div>
        )
    }

    return (
        <div>
            <div className="px-6 w-full">
                <LiveStatus />
                {TitleBlock()}
                <div className="datepicker-header">
                    <button
                        onClick={() => setSelectedDate(getPreviousDay(selectedDate))}
                        className="px-4 py-2 text-white font-semibold bg-blue-400 rounded hover:bg-blue-500"
                    >
                        ← {getDayFormatted(getPreviousDay(selectedDate))}
                    </button>
                    <div className="p-2 border-2 rounded-lg border-blue-400">
                        <DatePicker selected={selectedDate} onChange={setSelectedDate} dateFormat="dd/MM/yyyy"/>
                    </div>
                    <button
                        onClick={() => setSelectedDate(getNextDay(selectedDate))}
                        className="px-4 py-2 text-white font-semibold bg-blue-400 rounded hover:bg-blue-500"
                    >
                        {getDayFormatted(getNextDay(selectedDate))} →
                    </button>
                </div>
                <ArchiveSingle date={selectedDate}/>
            </div>
        </div>
    )
}

export default Archive