import logo from '../assets/townbeach-logo.png';

const Loader = () => (
    <div className="py-20 w-full justify-center flex-col items-center flex"> 
        <div className="flex-shrink-0 mb-5 sm:mb-10 space-x-2.5 flex items-center">
            <img src={logo} className="h-8" style={{height: '45px'}} alt="Townbeach Logo"/>
        </div>
        <div className="w-full m-0 sm:-m-4 flex items-center justify-center">
            <svg className="animate-spin h-10 w-10 text-blue-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
        </div>
    </div>
)

export default Loader