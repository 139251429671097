import React, { useState } from 'react';
import LiveStatus from './LiveStatus';
import { useSelector, useDispatch } from 'react-redux';
import Loader from './Loader';
import { saveCVC } from '../actions/cvc-actions';
import {useFirebase} from "../firebase-provider";

function CVC() {

    const cvc = useSelector(state => state.cvc.board);

    const { database } = useFirebase();

    const dispatch = useDispatch();

    const TitleBlock = () => {
        return (
            <div>
                <h2 className="text-2xl mt-2 font-bold items-center leading-7 text-gray-900 sm:text-3xl sm:truncate inline-flex sm:align-top">
                   City vs Country
                </h2>
            </div>
        )
    }

    const DaysList = (props) => {

        let [days, setNewDays] = useState(props.cvc);
        function setTeam1Name(value, gameIndex, dayIndex) {
            let oldDays = [...days];
            let currentDay = oldDays[dayIndex];
            let currentGame = currentDay[gameIndex];
            currentGame.team_1.name = value;
            setNewDays(oldDays)
        }

        function setTeam1Points(value, gameIndex, dayIndex){
            let oldDays = [...days];
            let currentDay = oldDays[dayIndex];
            let currentGame = currentDay[gameIndex];
            currentGame.team_1.points = value;
            setNewDays(oldDays)
        }

        function setTeam2Name(value, gameIndex, dayIndex){
            let oldDays = [...days];
            let currentDay = oldDays[dayIndex];
            let currentGame = currentDay[gameIndex];
            currentGame.team_2.name = value;
            setNewDays(oldDays)
        }

        function setTeam2Points(value, gameIndex, dayIndex){
            let oldDays = [...days];
            let currentDay = oldDays[dayIndex];
            let currentGame = currentDay[gameIndex];
            currentGame.team_2.points = value;
            setNewDays(oldDays)
        }

        const setTime = (value, gameIndex, dayIndex) => {
            let oldDays = [...days];
            let currentDay = oldDays[dayIndex];
            let currentGame = currentDay[gameIndex];
            currentGame.time = value;
            setNewDays(oldDays)
        }

        const removeDay = (day) => {
            let oldDays = [...days];
            oldDays.splice(day, 1);
            setNewDays(oldDays)
        }

        const removeGame = (day, game) => {
            let oldDays = [...days];
            let currentDay = oldDays[day];
            currentDay.splice(game, 1);
            setNewDays(oldDays)
        }

        const saveCurrentState = () => {
            saveCVC(days, dispatch, database)
        }

        if(days.length === 0) {
            return (
                <div>
                    <button onClick={() => {
                        let oldDays = [...days]
                        oldDays.push([])
                        setNewDays(oldDays)
                        }} className="bg-blue-400 rounded-md text-white font-medium text-lg hover:bg-blue-500 p-1 px-4 mt-2 cursor-pointer flex flex-row justify-center items-center">Add new day</button>
                    <button onClick={() => {
                        saveCurrentState()
                    }} className="bg-green-400 rounded-md text-white font-medium text-lg hover:bg-green-500 p-1 px-4 mt-2 cursor-pointer flex flex-row justify-center items-center">Save</button>
                    
                    <h5>No data found, Add a day to get started</h5>
                </div>
            )
        } else {
            return (
                <div>
                    <button onClick={() => {
                        let oldDays = [...days]
                        oldDays.push([])
                        setNewDays(oldDays)
                        }} className="bg-blue-400 rounded-md text-white font-medium text-lg hover:bg-blue-500 p-1 px-4 mt-2 cursor-pointer flex flex-row justify-center items-center">Add new day</button>
                    <button onClick={() => {
                        saveCurrentState()
                        }} className="bg-green-400 rounded-md text-white font-medium text-lg hover:bg-green-500 p-1 px-4 mt-2 cursor-pointer flex flex-row justify-center items-center">Save</button>
                    {days.map((day, dayIndex) => {
                        return (
                            <div key={dayIndex} className="mt-10">
                                <label className="block text-grey-darker text-sm font-bold mb-2">
                                    Day {dayIndex + 1}
                                </label>
                                <button onClick={() => {
                                    removeDay(dayIndex)
                                }} className="bg-red-400 rounded-md text-white font-medium text-lg hover:bg-red-500 p-1 px-4 mt-2 cursor-pointer flex flex-row justify-center items-center">Delete Day</button>
                                {day.length > 0 ? (
                                    <div key={"day_" + dayIndex} className="flex flex-row flex-wrap">
                                        {day.map((game, index) => {
                                            return (
                                                <div key={"game_" + dayIndex + "_" + index} className="bg-blue-100 m-3 p-2 flex flex-col w-auto rounded-md justify-center items-center">
                                                    <div className="mt-2 w-full flex flex-row">
                                                        <div className="w-1/2 max-w-2xl px-1">
                                                            <input key={"team_1_" + dayIndex + "_" + index} className="shadow appearance-none border rounded w-full py-2 text-grey-darker" defaultValue={game.team_1.name} onChange={e => {setTeam1Name(e.target.value, index, dayIndex)}} id={"team_1_" + dayIndex + "_" + index} type="text" placeholder="Team 1 Name"/>
                                                        </div>
                                                        <div className="w-1/2 max-w-2xl px-1">
                                                            <input className="shadow appearance-none border rounded w-full py-2 text-grey-darker" type="number" defaultValue={game.team_1.points} onChange={e => {setTeam1Points(e.target.value, index, dayIndex)}} id={"team_1_score_" + dayIndex + "_" + index}/>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2 w-full flex flex-row">
                                                        <div className="w-1/2 max-w-2xl px-1">
                                                            <input className="shadow appearance-none border rounded w-full py-2 text-grey-darker" defaultValue={game.team_2.name} onChange={e => {setTeam2Name(e.target.value, index, dayIndex)}} id={"team_2_" + dayIndex + "_" + index} type="text" placeholder="Team 2 Name"/>
                                                        </div>
                                                        <div className="w-1/2 max-w-2xl px-1">
                                                            <input className="shadow appearance-none border rounded w-full py-2 text-grey-darker" type="number" defaultValue={game.team_2.points} onChange={e => {setTeam2Points(e.target.value, index, dayIndex)}} id={"team_2_score_" + dayIndex + "_" + index}/>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2 flex justify-center items-center">
                                                        <input className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-grey-darker" defaultValue={game.time} onChange={e => {setTime(e.target.value, index, dayIndex)}} id={"time_" + dayIndex + "_" + index} type="text" placeholder="Time Started"/>
                                                        <button onClick={() => {
                                                            removeGame(dayIndex, index)
                                                        }} className="ml-2 bg-red-400 rounded-md w-1/2 text-white font-medium text-lg hover:bg-red-500 p-1 px-4 cursor-pointer flex flex-row justify-center items-center">Delete Game</button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                ) : <h1>No Games</h1>}
                                <button onClick={() => {
                                    let oldDays = [...days];
                                    oldDays[dayIndex].push({
                                        team_1: {
                                            points: 0,
                                            name: ""
                                        },
                                        team_2: {
                                            points: 0,
                                            name: ""
                                        },
                                        time: ""
                                    });
                                    setNewDays(oldDays);
                                }} className="bg-blue-400 rounded-md text-white font-medium text-lg hover:bg-blue-500 p-1 px-4 mt-2 cursor-pointer flex flex-row justify-center items-center">Add new score</button>
                            </div>
                        )
                    })}
                </div>
            )
        }
    }

    if(!cvc) {
        return (
            <Loader />
        )
    } else {
        return (
            <div>
                <div className="px-6">
                    <LiveStatus />
                    {TitleBlock()}
                    <DaysList cvc={cvc}/>
                </div>
            </div>
        )
    }
}

export default CVC