import { toggleNotification, changeMessage, changeType } from './notification-actions';
import { ref, set, push, remove } from "firebase/database";

export function changeTeamsInDraw(team1, team2, team1Change, team2Change, week, season, dispatch, database, callback) {
    // let seasonKeys = Object.keys(season);
    // let team1Changing = team1 !== team1Change;
    // let team2Changing = team2 !== team2Change;
    // let team1Index = [];
    // let team1ChangeIndex = [];
    // let team2Index = [];
    // let team2ChangeIndex = [];
    // for(let i = 0; i < seasonKeys.length; i++) {
    //     let gamesForWeek = season[seasonKeys[i]].games[week];
    //     for(let x = 0; x < gamesForWeek.length; x++) {
    //         switch(gamesForWeek[x][0]) {
    //             case team1:
    //                 team1Index.push(i);
    //                 team1Index.push(x);
    //                 team1Index.push(0);
    //                 break
    //             case team2:
    //                 team2Index.push(i);
    //                 team2Index.push(x);
    //                 team2Index.push(0);
    //                 break
    //             case team1Change:
    //                 team1ChangeIndex.push(i);
    //                 team1ChangeIndex.push(x);
    //                 team1ChangeIndex.push(0);
    //                 break
    //             case team2Change:
    //                 team2ChangeIndex.push(i);
    //                 team2ChangeIndex.push(x);
    //                 team2ChangeIndex.push(0);
    //                 break
    //             default:
    //                 break;
    //         }
    //         switch(gamesForWeek[x][1]) {
    //             case team1:
    //                 team1Index.push(i);
    //                 team1Index.push(x);
    //                 team1Index.push(1);
    //                 break
    //             case team2:
    //                 team2Index.push(i);
    //                 team2Index.push(x);
    //                 team2Index.push(1);
    //                 break
    //             case team1Change:
    //                 team1ChangeIndex.push(i);
    //                 team1ChangeIndex.push(x);
    //                 team1ChangeIndex.push(1);
    //                 break
    //             case team2Change:
    //                 team2ChangeIndex.push(i);
    //                 team2ChangeIndex.push(x);
    //                 team2ChangeIndex.push(1);
    //                 break
    //             default:
    //                 break;
    //         }
    //     }
    // }
    // if(team1Changing) {
    //     season[team1Index[0]].games[week][team1Index[1]][team1Index[2]] = team1Change
    //     season[team1ChangeIndex[0]].games[week][team1ChangeIndex[1]][team1ChangeIndex[2]] = team1
    // }
    // if(team2Changing) {
    //     season[team2Index[0]].games[week][team2Index[1]][team2Index[2]] = team2Change
    //     season[team2ChangeIndex[0]].games[week][team2ChangeIndex[1]][team2ChangeIndex[2]] = team2
    // }
    //
    // let gamesRef = ref(database, "season");
    //
    // set(gamesRef, season).then(() => {
    //     dispatch({
    //         type: "GAMES/MOVEDRAW/SUCCESS"
    //     })
    //     callback(true);
    // }).catch(() => {
    //     dispatch({
    //         type: "GAMES/MOVEDRAW/FAIL"
    //     })
    //     callback(false);
    // })
    callback(true);
}

export function addTeamToRounds(team, season, cloudSettings, dispatch, database, callback) {
    // dispatch({
    //     type: "GAMES/ADDTEAM/STARTED",
    // });
    // if(season.season !== null) {
    //     let seasonToEdit = [...season.season];
    //     let nextWeekDate = getNextStartDateIndex(cloudSettings);
    //     let compDivisionDoesExist = false;
    //     let indexOfEffectedDivInSeason;
    //     let listOfIndexWhereDayInSeason = [];
    //     for(let i = 0; i < seasonToEdit.length; i++) {
    //         if(seasonToEdit[i].day === team.avaliability.day && seasonToEdit[i].division === team.grade) {
    //             compDivisionDoesExist = true;
    //             indexOfEffectedDivInSeason = i;
    //         }
    //         if(seasonToEdit[i].day === team.avaliability.day){
    //             listOfIndexWhereDayInSeason.push(i)
    //         }
    //     }
    //
    //     if(compDivisionDoesExist) {
    //         // Get div that needs to be adjusted
    //         let divisionToAdjust = {...seasonToEdit[indexOfEffectedDivInSeason]};
    //
    //         // Remove div from season object
    //         seasonToEdit.splice(indexOfEffectedDivInSeason, 1);
    //
    //         // Split off old part
    //         let ogPart = {...divisionToAdjust};
    //         if(!ogPart.games) {
    //             return callback(true);
    //         } else {
    //             ogPart.games = ogPart.games.splice(0, nextWeekDate);
    //         }
    //
    //         // Generate division remaining season for new part
    //             // --------- Check if fill in first week requiring replacement.
    //         let weekOfGames = [...divisionToAdjust.games[0]];
    //         let fillToBeReplaced = false;
    //         for(let i = 0; i < weekOfGames.length; i++) {
    //             if(weekOfGames[i][0] === "fill"){
    //                 fillToBeReplaced = true;
    //                 break;
    //             } else if (weekOfGames[i][1] === "fill") {
    //                 fillToBeReplaced = true;
    //                 break;
    //             }
    //         }
    //
    //         let teamList1 = [];
    //         let teamList2 = [];
    //         let rotationTeam;
    //         let games = [];
    //
    //             // --------- Compile list of teams from first week of games
    //         for(let i = 0; i < weekOfGames.length; i++) {
    //             let team1;
    //             let team2;
    //             if(weekOfGames[i][0] === "fill") {
    //                 team1 = team.uid
    //                 team2 = weekOfGames[i][1]
    //             } else if (weekOfGames[i][1] === "fill") {
    //                 team2 = team.uid
    //                 team1 = weekOfGames[i][0]
    //             } else {
    //                 team1 = weekOfGames[i][0]
    //                 team2 = weekOfGames[i][1]
    //             }
    //             if(i === 0) {
    //                 rotationTeam = team1;
    //                 teamList1.push(team2);
    //             } else {
    //                 teamList1.push(team1);
    //                 teamList2.push(team2);
    //             }
    //         }
    //
    //             // --------- Add fill if needed
    //         if(!fillToBeReplaced) {
    //             teamList1.push("fill");
    //             teamList2.push(team.uid);
    //         }
    //
    //         let seasonIndexes = [];
    //         resetSeasonIndexes(seasonIndexes, cloudSettings);
    //
    //             // ------- Generate end of season with new team
    //         for(let x = nextWeekDate; x < cloudSettings.seasonLength; x++) {
    //             let week = [];
    //             for(let j = 0; j < teamList1.length; j++) {
    //                 let [court, timeSlot] = getDayTimeSlotAndCourt(x, team.avaliability.day, seasonIndexes);
    //                 if(j === 0) {
    //                     week.push([rotationTeam, teamList1[j], crypto.randomUUID(), timeSlot, court, [rotationTeam, teamList1[j], timeSlot, court]])
    //                 } else {
    //                     week.push([teamList1[j], teamList2[j - 1], crypto.randomUUID(), timeSlot, court, [teamList1[j], teamList2[j - 1], timeSlot, court]])
    //                 }
    //             }
    //             games.push(week);
    //             resetSeasonIndexes(seasonIndexes, cloudSettings);
    //             if(teamList2.length > 0) {
    //                 let endList1 = teamList1.pop();
    //                 let startList2 = teamList2.shift();
    //
    //                 teamList2.push(endList1);
    //                 teamList1.unshift(startList2);
    //             }
    //         }
    //
    //          // Combine with old games object
    //         ogPart.games = ogPart.games.concat(games)
    //
    //         // Combine with season object
    //         seasonToEdit.push(ogPart)
    //
    //         resetSeasonIndexes(seasonIndexes, cloudSettings);
    //
    //         listOfIndexWhereDayInSeason = [];
    //
    //         for(let i = 0; i < seasonToEdit.length; i++) {
    //             if(seasonToEdit[i].day === team.avaliability.day){
    //                 listOfIndexWhereDayInSeason.push(i)
    //             }
    //         }
    //
    //         // Reset ENTIRE season from next week onward in terms of timeslots including MATCHING DAYS
    //         for(let y = 0; y < listOfIndexWhereDayInSeason.length; y++){
    //             let gamesInDivision = [...seasonToEdit[listOfIndexWhereDayInSeason[y]].games];
    //             for(let k = nextWeekDate; k < gamesInDivision.length; k++) {
    //                 for(let l = 0; l < gamesInDivision[k].length; l++) {
    //                     let [court, timeSlot] = getDayTimeSlotAndCourt(k, team.avaliability.day, seasonIndexes);
    //                     gamesInDivision[k][l][3] = timeSlot;
    //                     gamesInDivision[k][l][4] = court;
    //                 }
    //             }
    //         }
    //     } else {
    //         // Only here if no division exists on this day
    //         let games = [];
    //         for(let x = 0; x < cloudSettings.seasonLength; x++) {
    //             let [court, timeSlot] = getLatestGameIndex(team.avaliability.day, x, seasonToEdit, cloudSettings);
    //             games.push([["fill", team.uid, crypto.randomUUID(), timeSlot, court, ["fill", team.uid, timeSlot, court]]])
    //         }
    //         seasonToEdit.push({day: team.avaliability.day, division: team.grade, games: games})
    //     }
    //
    //     // ------- Upload season
    //     let gamesRef = ref(database, "season");
    //
    //     set(gamesRef, seasonToEdit).then(() => {
    //         dispatch({
    //             type: "GAMES/ADDTEAM/SUCCESS"
    //         })
    //         callback(true);
    //     }).catch(() => {
    //         dispatch({
    //             type: "GAMES/ADDTEAM/FAIL"
    //         })
    //         callback(false);
    //     })
    //     // ------- END Upload season
    // } else {
    //     callback(true);
    // }
    callback(true);
}

export function removeTeamFromGamesWithUid(teamUid, season, cloudSettings, dispatch, database, callback) {
    // dispatch({
    //     type: "GAMES/REMOVETEAM/STARTED",
    // });
    // let seasonDivIndex;
    // let fillDoesExist;
    // if(!season) {
    //     callback(true);
    //     return;
    // }
    // if(season.length > 0) {
    //     for(let i = 0; i < season.length; i++) {
    //         let seasonDiv = season[i];
    //         let divGames = seasonDiv.games;
    //         if(!divGames) {
    //             callback(true);
    //             return;
    //         }
    //         for(let j = 0; j < divGames.length; j++) {
    //             let weekOfGames = divGames[j];
    //             for(let x = 0; x < weekOfGames.length; x++) {
    //                 if(weekOfGames[x][0] === teamUid || weekOfGames[x][1] === teamUid) {
    //                     seasonDivIndex = i;
    //                 }
    //             }
    //         }
    //     }
    //     let seasonWithTeam = season[seasonDivIndex].games;
    //     for(let i = 0; i < seasonWithTeam.length; i++) {
    //         let weekOfGames = seasonWithTeam[i];
    //         for(let x = 0; x < weekOfGames.length; x++) {
    //             if(weekOfGames[x][0] === "fill" || weekOfGames[x][1] === "fill") {
    //                 fillDoesExist = true;
    //             }
    //         }
    //     }
    //     if(seasonDivIndex !== undefined) {
    //         if(!fillDoesExist) {
    //             for(let i = 0; i < seasonWithTeam.length; i++) {
    //                 let weekOfGames = seasonWithTeam[i];
    //                 for (let j = 0; j < weekOfGames.length; j++) {
    //                     if(weekOfGames[j][0] === teamUid) {
    //                         weekOfGames[j][0] = "fill"
    //                     } else if (weekOfGames[j][1] === teamUid) {
    //                         weekOfGames[j][1] = "fill"
    //                     }
    //                 }
    //             }
    //         } else {
    //             for(let i = 0; i < seasonWithTeam.length; i++) {
    //                 let weekOfGames = seasonWithTeam[i];
    //                 let fillGameLocationOpposingTeam;
    //                 let day = season[seasonDivIndex].day;
    //                 for(let j = 0; j < weekOfGames.length; j++) {
    //                     let removed;
    //                     if(weekOfGames[j][0] === "fill") {
    //                         fillGameLocationOpposingTeam = weekOfGames[j][1]
    //                         readjustTeamsForRemovalAt(i, weekOfGames[j][4], weekOfGames[j][3], season, day)
    //                         removed = weekOfGames.splice(j, 1)
    //
    //                     } else if(weekOfGames[j][1] === "fill") {
    //                         fillGameLocationOpposingTeam = weekOfGames[j][0]
    //                         readjustTeamsForRemovalAt(i, weekOfGames[j][4], weekOfGames[j][3], season, day)
    //                         removed = weekOfGames.splice(j, 1)
    //                     }
    //                     if (removed) {
    //                         if(weekOfGames.length === 0) {
    //                             removed[0] = "fill"
    //                             removed[1] = "fill"
    //                             weekOfGames.push(removed)
    //                         }
    //                     }
    //                 }
    //                 if(!fillGameLocationOpposingTeam) {
    //                     fillGameLocationOpposingTeam = "fill"
    //                 }
    //                 for(let j = 0; j < weekOfGames.length; j++) {
    //                     if(weekOfGames[j][0] === teamUid) {
    //                         weekOfGames[j][0] = fillGameLocationOpposingTeam
    //                     } else if(weekOfGames[j][1] === teamUid) {
    //                         weekOfGames[j][1] = fillGameLocationOpposingTeam
    //                     }
    //                 }
    //             }
    //         }
    //
    //         let gamesRef = ref(database, "season");
    //         set(gamesRef, season).then(() => {
    //             dispatch({
    //                 type: "GAMES/REMOVETEAM/SUCCESS"
    //             })
    //             callback(true);
    //         }).catch(() => {
    //             dispatch({
    //                 type: "GAMES/REMOVETEAM/FAIL"
    //             })
    //             callback(false);
    //         })
    //     } else {
    //         callback(false);
    //     }
    // } else {
    //     callback(true);
    // }
    // callback(false);
    callback(true);
}

function readjustTeamsForRemovalAt(seasonIndex, court, timeIndex, season, day) {
    let playedDays = []
    for(let i = 0; i < season.length; i++) {
        if(season[i].day === day) {
            playedDays.push(season[i]);
        }
    }
    for(let i = 0; i < playedDays.length; i++) {
        let weekGames = playedDays[i].games[seasonIndex];
        for(let j = 0; j < weekGames.length; j++) {
            if(weekGames[j][4] === court && weekGames[j][3] > timeIndex ) {
                weekGames[j][3] -= 1;
            }
        }
    }
}

function getLatestGameIndex(day, weekIndex, oldSeason, cloudSettings){
    let courts = [];
    for(let i = 0; i < cloudSettings.courts.length; i++) {
        courts.push(0);
    }
    for(let i = 0; i < oldSeason.length; i++){
        if(day === oldSeason[i].day) {
            let games = oldSeason[i].games[weekIndex];
            for(let j = 0; j < games.length; j++) {
                if(games[j][3] > courts[games[j][4]]) {
                    courts[games[j][4]] = games[j][3];
                }
            }
        }
    }
    let maxCourtValue = 0;
    let court;

    for(let i = 0; i < courts.length; i++) {
        if(courts[i] >= maxCourtValue) {
            maxCourtValue = courts[i];
            court = i;
        }
    }
    return [court, maxCourtValue + 1];
}

function getNextStartDateIndex(cloudSettings) {
    let date = new Date();
    for(let i = 0; i < cloudSettings.startDates.length; i++) {
        if(date < new Date(cloudSettings.startDates[i])) {
            return i;
        }
    }
}

export function moveRounds(index, newDate, dispatch, cloudSettings, database) {
    // let settingsRef = ref(database, "settings/startDates/");
    // let tempDates = [...cloudSettings.startDates];
    // let direction;
    // for(let i = index; i < cloudSettings.seasonLength; i++){
    //     if(i === index) {
    //         if (newDate < new Date(tempDates[i])) {
    //             direction = -7
    //         } else {
    //             direction = 7
    //         }
    //         tempDates[i] = newDate.toString();
    //     } else {
    //         let currentDate = new Date(tempDates[i])
    //         let nextDate = new Date(currentDate.getTime());
    //         nextDate.setDate(currentDate.getDate() + direction);
    //         tempDates[i] = nextDate.toString();
    //     }
    // }
    // dispatch({
    //     type: "GAMES/GENERATE/STARTED",
    // });
    //
    // set(settingsRef, tempDates).then(() => {
    //     dispatch({
    //         type: "GAMES/GENERATE/SUCCESS"
    //     })
    //     changeMessage("Weekly start date updated successfully", dispatch)
    //     changeType("SUCCESS", dispatch)
    //     toggleNotification(dispatch)
    // })
}

export function moveUpQueue(gameUID, weekIndex, season, dispatch, database) {
    // let newSeason = [...season];
    // let selectedGameDay;
    // let current = getAbsoluteLinkToCurrentGame();
    // let next;
    // if(current[3] !== 0) {
    //     next = getAbsoluteLinkToNextGame(current[3] - 1, current[4]);
    //
    //     current[3] -= 1;
    //     next[3] += 1;
    // } else {
    //     changeMessage("Game is already as early as possible", dispatch)
    //     changeType("ERROR", dispatch)
    //     toggleNotification(dispatch)
    // }
    //
    // function getAbsoluteLinkToCurrentGame() {
    //     let dayDivTeamsKeys = Object.keys(newSeason);
    //     for(let i = 0; i < dayDivTeamsKeys.length; i++) {
    //         let currentWeek = newSeason[dayDivTeamsKeys[i]]["games"][weekIndex];
    //         for(let j = 0; j < currentWeek.length; j ++) {
    //             if(currentWeek[j][2] === gameUID) {
    //                 selectedGameDay = newSeason[dayDivTeamsKeys[i]]["day"];
    //                 return currentWeek[j];
    //             }
    //         }
    //     }
    // }
    //
    // function getAbsoluteLinkToNextGame(nextIndex, courtIndex) {
    //     let dayDivTeamsKeys = Object.keys(newSeason);
    //     for(let i = 0; i < dayDivTeamsKeys.length; i++) {
    //         if(newSeason[dayDivTeamsKeys[i]]["day"] === selectedGameDay) {
    //             let currentWeek = newSeason[dayDivTeamsKeys[i]]["games"][weekIndex];
    //             for(let j = 0; j < currentWeek.length; j ++) {
    //                 if(currentWeek[j][3] === nextIndex && currentWeek[j][4] === courtIndex) {
    //                     return currentWeek[j];
    //                 }
    //             }
    //         }
    //     }
    // }
    //
    // let gamesRef = ref(database, "season/");
    //
    // dispatch({
    //     type: "GAMES/GENERATE/STARTED",
    // });
    //
    // set(gamesRef, newSeason).then(() => {
    //     dispatch({
    //         type: "GAMES/GENERATE/SUCCESS"
    //     })
    // })
}

export function moveDownQueue(gameUID, weekIndex, season, dispatch, database) {
    // let newSeason = [...season];
    // let selectedGameDay;
    // let current = getAbsoluteLinkToCurrentGame();
    // let next;
    // let max = getMax();
    // if(current[3] !== max) {
    //     next = getAbsoluteLinkToNextGame(current[3] + 1, current[4]);
    //
    //     current[3] += 1;
    //     next[3] -= 1;
    // } else {
    //     changeMessage("Game is already as early as possible", dispatch)
    //     changeType("ERROR", dispatch)
    //     toggleNotification(dispatch)
    // }
    //
    // function getMax() {
    //     let max = 0;
    //     let dayDivTeamsKeys = Object.keys(newSeason);
    //     for(let i = 0; i < dayDivTeamsKeys.length; i++) {
    //         if(newSeason[dayDivTeamsKeys[i]]["day"] === selectedGameDay) {
    //             let currentWeek = newSeason[dayDivTeamsKeys[i]]["games"][weekIndex];
    //             for(let j = 0; j < currentWeek.length; j ++) {
    //                 if(currentWeek[j][3] > max && currentWeek[j][4] === current[4]) {
    //                     max = currentWeek[j][3];
    //                 }
    //             }
    //         }
    //     }
    //     return max;
    // }
    //
    // function getAbsoluteLinkToCurrentGame() {
    //     let dayDivTeamsKeys = Object.keys(newSeason);
    //     for(let i = 0; i < dayDivTeamsKeys.length; i++) {
    //         let currentWeek = newSeason[dayDivTeamsKeys[i]]["games"][weekIndex];
    //         for(let j = 0; j < currentWeek.length; j ++) {
    //             if(currentWeek[j][2] === gameUID) {
    //                 selectedGameDay = newSeason[dayDivTeamsKeys[i]]["day"];
    //                 return currentWeek[j];
    //             }
    //         }
    //     }
    // }
    //
    // function getAbsoluteLinkToNextGame(nextIndex, courtIndex) {
    //     let dayDivTeamsKeys = Object.keys(newSeason);
    //     for(let i = 0; i < dayDivTeamsKeys.length; i++) {
    //         if(newSeason[dayDivTeamsKeys[i]]["day"] === selectedGameDay) {
    //             let currentWeek = newSeason[dayDivTeamsKeys[i]]["games"][weekIndex];
    //             for(let j = 0; j < currentWeek.length; j ++) {
    //                 if(currentWeek[j][3] === nextIndex && currentWeek[j][4] === courtIndex) {
    //                     return currentWeek[j];
    //                 }
    //             }
    //         }
    //     }
    // }
    //
    // let gamesRef = ref(database, "season/");
    //
    // dispatch({
    //     type: "GAMES/GENERATE/STARTED",
    // });
    //
    // set(gamesRef, newSeason).then(() => {
    //     dispatch({
    //         type: "GAMES/GENERATE/SUCCESS"
    //     })
    // })
}

export function changeSide(gameUID, weekIndex, season, changeToSide, dispatch, database) {
    // let newSeason = [...season];
    // let selectedGameDay;
    // let current = getAbsoluteLinkToCurrentGame();
    // let timeSlotOtherSide = getTimeForNewGameOnSide(changeToSide);
    // let previousTimeSlot = [...current][3];
    // let previousCourt = [...current][4];
    // current[3] = timeSlotOtherSide;
    // current[4] = changeToSide;
    // realignTimesOnOGSide(previousTimeSlot, previousCourt)
    //
    // function getAbsoluteLinkToCurrentGame() {
    //     let dayDivTeamsKeys = Object.keys(newSeason);
    //     for(let i = 0; i < dayDivTeamsKeys.length; i++) {
    //         let currentWeek = newSeason[dayDivTeamsKeys[i]]["games"][weekIndex];
    //         for(let j = 0; j < currentWeek.length; j ++) {
    //             if(currentWeek[j][2] === gameUID) {
    //                 selectedGameDay = newSeason[dayDivTeamsKeys[i]]["day"];
    //                 return currentWeek[j];
    //             }
    //         }
    //     }
    // }
    //
    // function getTimeForNewGameOnSide(changeToSide) {
    //     let dayDivTeamsKeys = Object.keys(newSeason);
    //     let slot = 0;
    //     let wasChanged = false;
    //     for(let i = 0; i < dayDivTeamsKeys.length; i++) {
    //         if(newSeason[dayDivTeamsKeys[i]]["day"] === selectedGameDay) {
    //             let games = newSeason[dayDivTeamsKeys[i]]["games"][weekIndex];
    //             for(let j = 0; j < games.length; j++) {
    //                 if(games[j][4] === changeToSide) {
    //                     if(games[j][3] >= slot) {
    //                         wasChanged = true;
    //                         slot = games[j][3]
    //                     }
    //                 }
    //             }
    //         }
    //     }
    //     if(slot !== 0 || wasChanged) {
    //         slot += 1;
    //     }
    //     return slot;
    // }
    //
    // function realignTimesOnOGSide(previousTimeSlot, previousCourt) {
    //     let dayDivTeamsKeys = Object.keys(newSeason);
    //     for(let i = 0; i < dayDivTeamsKeys.length; i++) {
    //         if(newSeason[dayDivTeamsKeys[i]]["day"] === selectedGameDay) {
    //             let games = newSeason[dayDivTeamsKeys[i]]["games"][weekIndex];
    //             for(let j = 0; j < games.length; j++) {
    //                 if(games[j][4] === previousCourt) {
    //                     if(games[j][3] > previousTimeSlot) {
    //                         games[j][3] -= 1;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }
    //
    // let gamesRef = ref(database, "season/");
    //
    // dispatch({
    //     type: "GAMES/GENERATE/STARTED",
    // });
    //
    // set(gamesRef, newSeason).then(() => {
    //     dispatch({
    //         type: "GAMES/GENERATE/SUCCESS"
    //     })
    // })
}

export function saveSeasonAndMove(games, dispatch, database) {
    // dispatch({
    //     type: "GAMES/ARCHIVE/STARTED",
    // });
    //
    // let season = games.season;
    // let oldSeasonRef = ref(database, "season/");
    // let archiveRef = ref(database, "archives/");
    // let archive = {
    //     end_date: new Date().toDateString(),
    //     season_data: season
    // }
    // push(archiveRef, archive).then(() => {
    //     remove(oldSeasonRef).then(() => {
    //         dispatch({
    //             type: "GAMES/ARCHIVE/SUCCESS"
    //         })
    //     })
    // })
}

export function generateSeason(teams, startDate, cloudSettings, seasonName, dispatch, database) {
    // if(teams === null) {
    //     changeMessage("No teams are made or something went wrong. Check there are teams and try again.", dispatch)
    //     changeType("ERROR", dispatch)
    //     toggleNotification(dispatch)
    // } else {
    //     let keys = Object.keys(teams);
    //     let teamsPerDay = {};
    //
    //     for(let i = 0; i < cloudSettings.playingDays.length; i++) {
    //         let localDay = cloudSettings.playingDays[i].friendlyName;
    //         teamsPerDay[localDay] = {};
    //         for(let j = 0; j < cloudSettings.divisions.length; j++) {
    //             teamsPerDay[localDay][j] = [];
    //         }
    //     }
    //     for(let i = 0; i < keys.length; i++) {
    //         let team = teams[keys[i]];
    //         let day = team.avaliability.day;
    //         if(team.display_only === undefined || team.display_only === false) {
    //             teamsPerDay[day][team.grade].push(team);
    //         }
    //     }
    //
    //     let newSeason = [];
    //
    //     let dayKeys = Object.keys(teamsPerDay);
    //
    //     for(let i = 0; i < dayKeys.length; i++){
    //         let divArray = teamsPerDay[dayKeys[i]]
    //         let divArrayKeys = Object.keys(divArray);
    //
    //         let seasonIndexes = [];
    //         resetSeasonIndexes(seasonIndexes, cloudSettings);
    //         for(let j = 0; j < divArrayKeys.length; j++){
    //             let unSyncedSeason = {games: [], day: "", division: ""};
    //             let division = divArray[divArrayKeys[j]];
    //             if(division.length%2 !== 0) {
    //                 division.push("fill");
    //             }
    //
    //             let teamList1 = [];
    //             let teamList2 = [];
    //             let staticTeam;
    //
    //             for(let x = 0; x < division.length; x++) {
    //                 if(x < (division.length/2)) {
    //                     teamList1.push(division[x]);
    //                 } else {
    //                     teamList2.push(division[x]);
    //                 }
    //             }
    //
    //             staticTeam = teamList2.pop();
    //
    //             for(let x = 0; x < cloudSettings.seasonLength; x++) {
    //                 let games = [];
    //                 let gameDivision;
    //                 let gameDay;
    //                 if(teamList1.length > 0) {
    //                     for(let k = 0; k < teamList1.length; k++) {
    //                         let game = [];
    //                         if (k === 0) {
    //                             if(teamList1[0] === "fill") {
    //                                 game.push(teamList1[0]);
    //                             } else {
    //                                 game.push(teamList1[0].uid);
    //                                 gameDivision = teamList1[0].grade;
    //                                 gameDay = teamList1[0].avaliability.day;
    //                             }
    //                             if(staticTeam === "fill") {
    //                                 game.push(staticTeam);
    //                             } else {
    //                                 game.push(staticTeam.uid);
    //                                 gameDivision = staticTeam.grade;
    //                                 gameDay = staticTeam.avaliability.day;
    //                             }
    //                         } else {
    //                             if(teamList1[k] === "fill") {
    //                                 game.push(teamList1[k]);
    //                             } else {
    //                                 game.push(teamList1[k].uid);
    //                                 gameDivision = teamList1[k].grade;
    //                                 gameDay = teamList1[k].avaliability.day;
    //                             }
    //
    //                             if(teamList2[k - 1] === "fill") {
    //                                 game.push(teamList2[k - 1])
    //                             } else {
    //                                 game.push(teamList2[k - 1].uid);
    //                                 gameDivision = teamList2[k - 1].grade;
    //                                 gameDay = teamList2[k - 1].avaliability.day;
    //                             }
    //                         }
    //                         game.push(crypto.randomUUID());
    //                         let [court, timeSlot] = getDayTimeSlotAndCourt(x, gameDay, seasonIndexes);
    //                         game.push(timeSlot);
    //                         game.push(court);
    //                         game.push([game[0], game[1], game[3], game[4]]);
    //                         games.push(game);
    //                     }
    //                     if(teamList2.length > 0) {
    //                         let endList1 = teamList1.pop();
    //                         let startList2 = teamList2.shift();
    //
    //                         teamList2.push(endList1);
    //                         teamList1.unshift(startList2);
    //                     }
    //                     unSyncedSeason.games.push(games)
    //                     unSyncedSeason.day = gameDay;
    //                     unSyncedSeason.division = gameDivision;
    //                 }
    //             }
    //             if(unSyncedSeason.games.length !== 0) {
    //                 newSeason.push(unSyncedSeason)
    //             }
    //         }
    //     }
    //     let startDates = [];
    //     let actualStartDate = new Date(startDate.getTime());
    //     actualStartDate.setDate(startDate.getDate() - 7);
    //     for(let i = 0; i < cloudSettings.seasonLength; i++) {
    //         startDates.push(getStartDateAtIndex(i, actualStartDate).toDateString())
    //     }
    //     let cloudSettingsRef = ref(database, "settings/startDates");
    //     let nameSettingsRef = ref(database, "settings/seasonName");
    //     let gamesRef = ref(database, "season");
    //     dispatch({
    //         type: "GAMES/GENERATE/STARTED",
    //     });
    //     set(cloudSettingsRef, startDates).then(() => {
    //         set(gamesRef, newSeason).then(() => {
    //             set(nameSettingsRef, seasonName).then(() => {
    //                 dispatch({
    //                     type: "GAMES/GENERATE/SUCCESS"
    //                 })
    //                 changeMessage("Season generated successfully", dispatch)
    //                 changeType("SUCCESS", dispatch)
    //                 toggleNotification(dispatch)
    //             })
    //         })
    //     })
    // }
}

function resetSeasonIndexes(seasonIndexes, cloudSettings) {
    for(let x = 0; x < cloudSettings.seasonLength; x++) {
        seasonIndexes.push({});
        for(let i = 0; i < cloudSettings.playingDays.length; i++) {
            let localDay = cloudSettings.playingDays[i].friendlyName;
            seasonIndexes[x][localDay] = {};
            for(let j = 0; j < cloudSettings.courts.length; j++) {
                seasonIndexes[x][localDay][j] = 0;
            }
        }
    }
}

function getDayTimeSlotAndCourt(roundNum, gameDay, seasonIndexes) {
    let today = seasonIndexes[roundNum][gameDay];
    let courtIndexes = Object.keys(today);
    let courtWithLeastGames = courtIndexes[0];
    for(let i = 0; i < courtIndexes.length; i++){
        if(today[courtIndexes[i]] < today[courtWithLeastGames]) {
            courtWithLeastGames = i;
        }
    }
    today[courtWithLeastGames] += 1;
    return [parseInt(courtWithLeastGames), parseInt(today[courtWithLeastGames] - 1)];
}


function getStartDateAtIndex(index, startDate) {
    let weekNum = index + 1;
    let nextDate = new Date(startDate.getTime());
    nextDate.setDate(startDate.getDate() + (7*weekNum));
    return nextDate;
}