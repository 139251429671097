import React, { useState } from 'react';
import { togglePlayerWizard, addPlayer, clearWizard } from '../actions/player-actions';
import { changeMessage, changeType, toggleNotification } from '../actions/notification-actions';
import { useDispatch, useSelector } from 'react-redux';
import {useFirebase} from "../firebase-provider";

function NewPlayer() {

    const dispatch = useDispatch();

    const { database } = useFirebase();

    const PlayerTitleName = (props) => (
        <div className="mb-5 mt-6 lg:flex lg:items-center lg:justify-between">
            <div className="flex-1 flex justify-between mb-2.5 sm:mb-0 min-w-0">
                <h2 className="text-2xl font-bold items-center leading-7 text-gray-900 sm:text-3xl sm:truncate inline-flex sm:align-top">
                    Add Player
                </h2>
                <button onClick={() => {clearWizard(dispatch); togglePlayerWizard(dispatch)}} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-400 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                    Cancel
                </button>
            </div>
        </div>
    );

    const PlayerForm = (props) => {

        const players = useSelector(state => state.players)

        let tempState = {
            first_name: "",
            last_name: "",
            dob: "",
            mobile: "",
            email: "",
            uid: crypto.randomUUID()
        }
    
        if(players.editing_player) {
            for(let i = 0; i < Object.keys(players.players).length; i++) {
                if(players.editing_player === players.players[Object.keys(players.players)[i]].uid) {
                    tempState = players.players[Object.keys(players.players)[i]]
                }
            }
        }
    
        let [state, setState] = useState(tempState)

        function proccessPlayer() {
            let message = "Please ensure you have entered the first name, last name and date of birth"
            if (state.first_name.length === 0 || state.last_name.length === 0 || state.dob.length === 0 ) {
                changeType("ERROR", dispatch)
                changeMessage(message, dispatch)
                toggleNotification(dispatch)
            } else {
                addPlayer(state, dispatch, database)
            }
        }
    
        const mask = e => {
            let v = e.target.value;
            if(v.length > state.dob.length) {
                let lastChar = v[v.length - 1];
                let output;
                if((lastChar>= '0' && lastChar <= '9') || lastChar === '/') {
                    if(v.length > 5 && v[5] !== '/') {
                        let b = '/'
                        output = [v.slice(0, 5), b, v.slice(5)].join('');
                    } else if(v.length > 2 && v[2] !== '/') {
                        let b = '/'
                        output = [v.slice(0, 2), b, v.slice(2)].join('');
    
                    } else {
                        output = v
                    }
                } else {
                    output = state.dob
                }
                setState({...state, dob: output})
            } else {
                setState({...state, dob: v})
            }
        }

        const ButtonLoader = () => {
            if(players.uploading_player) {
                return (
                    <svg className="inline animate-spin -ml-1 mr-3 h-5 w-5 -ml-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                )
            } else {
                return null
            }
        }

        return (
            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
                <div className="mb-4">
                    <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="first_name">
                        First Name
                    </label>
                    <input key="first_name" className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" value={state.first_name} onChange={e => setState({...state, first_name: e.target.value })} id="first_name" type="text" placeholder="First Name" />
                </div>
                <div className="mb-4">
                    <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="last_name">
                        Last Name
                    </label>
                    <input key="last-name" className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" value={state.last_name} onChange={e => setState({...state, last_name: e.target.value })} id="last_name" type="text" placeholder="Last Name" />
                </div>
                <div className="mb-4">
                    <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="dob">
                        Date of Birth
                    </label>
                    <input key="dob" className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" maxLength="10" value={state.dob} onChange={e => mask(e)} id="dob" type="text" placeholder="DD/MM/YYYY" />
                </div>
                <div className="mb-4">
                    <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="email">
                        Email
                    </label>
                    <input key="email" className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" value={state.email} onChange={e => setState({...state, email: e.target.value })} id="email" type="text" placeholder="person@email.com" />
                </div>
                <div className="mb-4">
                    <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="mobile">
                        Mobile
                    </label>
                    <input key="mobile" className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" maxLength="10" value={state.mobile} onChange={e => setState({...state, mobile: e.target.value })} id="mobile" type="text" placeholder="0400001234" />
                </div>
                <div className="mb-4">
                    <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="uid">
                        Unique Identifier
                    </label>
                    <input key="uid" className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="uid" type="text" disabled placeholder={state.uid} />
                </div>
                <div className="flex items-end">
                    <button onClick={() => proccessPlayer()} className="w-full bg-blue-400 rounded-md text-white font-bold text-lg hover:bg-blue-500 p-2 mt-8 cursor-pointer">
                        <ButtonLoader/>
                        Save Player
                    </button>
                </div>
            </div>
        )
    };

    return (
        <div className="px-6">
            <PlayerTitleName />
            <PlayerForm />
        </div>
    )
}

export default NewPlayer;