const playerReducer = (
    state = {
        players: [],
        wizard: false,
        editing_player: null,
        new_player: {
            first_name: "",
            last_name: "",
            dob: "",
            uid: ""
        },
        uploading_player: false
    }, action
    ) => {
    switch (action.type) {
        case 'PLAYERS/EDIT':
            return {
                ...state,
                editing_player: action.payload
            }
        case 'PLAYERS/UPDATE/SUBMITTED':
            return {
                ...state,
            }
        case 'PLAYERS/UPDATE/SUCCESS':
            return {
                ...state,
                players: action.payload,

            }
        case 'PLAYERS/ADD/SUBMITTED':
            return {
                ...state,
                uploading_player: true
            }
        case 'PLAYERS/ADD/SUCCESS':
            return {
                ...state,
                uploading_player: false,
                editing_player: null,
                wizard: false
            }
        case 'PLAYERS/REMOVE/SUBMITTED':
            return {
                ...state,
            }
        case 'PLAYERS/REMOVE/SUCCESS':
            return {
                ...state,
            }
        case 'PLAYERS/WIZARD/TOGGLE':
            return {
                ...state,
                wizard: !state.wizard
            }
        case 'PLAYERS/WIZARD/CLEAR':
            return {
                ...state,
                editing_player: null
            }
        default:
            return state
    }
}

export default playerReducer