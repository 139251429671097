const gamesReducer = (
    state = {
        season: [],
        bi_wizard: false,
        loading: true
    }, action
    ) => {
    const DAYS = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
    switch (action.type) {
        case 'GAMES/UPDATE/SUBMITTED':
            return {
                ...state
            }
        case 'GAMES/UPDATE/SUCCESS':
            let season = action.payload;
            let newSeason = [];
            if(season !== null) {
                for(let i = 0; i < Object.keys(DAYS).length; i++) {
                    for(let j = 0; j < season.length; j++) {
                        if(season[j].day === DAYS[Object.keys(DAYS)[i]]) {
                            newSeason.push(season[j])
                        }
                    }
                }
            }
            return {
                ...state,
                season: newSeason,
                loading: false
            }
        case 'GAMES/GENERATE/STARTED':
            return {
                ...state
            }
        case 'GAMES/GENERATE/SUCCESS':
            return {
                ...state
            }
        default:
            return state
    }
}

export default gamesReducer