import { ref, set } from "firebase/database";
import { toggleNotification, changeMessage, changeType } from './notification-actions';

export function saveCVC(data, dispatch, database) {
    dispatch({
        type: "CVC/UPDATE/SUBMITTED",
    });

    let resultBoard = ref(database, "resultBoard/");
    set(resultBoard, data).then(() => {
        changeMessage("CVC changes saved", dispatch)
        changeType("SUCCESS", dispatch)
        toggleNotification(dispatch)
    })
    dispatch({
        type: "CVC/UPDATE/SUCCESS",
        payload: data
    });

}